import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_import_error_table = _resolveComponent("import-error-table")!

  return (_openBlock(), _createBlock(_component_import_error_table, {
    "import-error-service": _ctx.productionPartNoticeService,
    "push-notification-subscription-key": _ctx.NotificationStoreEvents.MASTER_DATA.IMPORT_ERRORS.PFEP,
    "table-columns": _ctx.tableColumns,
    "table-key": "pfepList",
    "table-title": _ctx.getTranslation('core.domain.importErrorsPlanForEveryPart'),
    "update-data-route": _ctx.updateDataRoute
  }, {
    "cell(effectiveDateFrom)": _withCtx((row) => [
      (row.item.effectiveDateFrom)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_simple_date_popover, {
              date: row.item.effectiveDateFrom
            }, null, 8, ["date"])
          ]))
        : _createCommentVNode("", true)
    ]),
    "cell(effectiveDateTo)": _withCtx((row) => [
      (row.item.effectiveDateTo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_simple_date_popover, {
              date: row.item.effectiveDateTo
            }, null, 8, ["date"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["import-error-service", "push-notification-subscription-key", "table-columns", "table-title", "update-data-route"]))
}