
import { defineComponent } from 'vue';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import NotificationStoreEvents from '@/constants/NotificationStoreEvents';
import ProductionPartNotice from '@/domain/ProductionPartNotice';
import ImportErrorTable from '@/modules/master-data/views/import-errors/components/ImportErrorTable.vue';
import ProductionPartNoticeService from '@/services/ProductionPartNoticeService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import { ColumnDefinition } from '@/types';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';

export default defineComponent({
    name: 'production-part-notice',
    components: {
        ImportErrorTable,
        SimpleDatePopover,
    },
    setup() {
        const productionPartNoticeService = new ProductionPartNoticeService();
        const updateDataRoute = MasterDataRouteTypes.IMPORT_ERRORS.UPDATE_PFEP;
        const tableColumns: ColumnDefinition<ProductionPartNotice>[] = [
            {
                key: 'partNumber',
                label: getTitleCaseTranslation('core.domain.partNumber'),
            },
            {
                key: 'fromLocation',
                label: getTitleCaseTranslation('core.domain.fromLocation'),
            },
            {
                key: 'toLocation',
                label: getTitleCaseTranslation('core.domain.toLocation'),
            },
            {
                key: 'containerItemNumber',
                label: getTitleCaseTranslation('core.domain.containerNumber'),
            },
            {
                key: 'quantityPerContainer',
                label: getTitleCaseTranslation('core.domain.quantityPerContainer'),
            },
            {
                key: 'palletized',
                label: getTitleCaseTranslation('core.domain.palletized'),
            },
            {
                key: 'palletContainerItemNumber',
                label: getTitleCaseTranslation('core.domain.palletContainerNumber'),
            },
            {
                key: 'containersPerPallet',
                label: getTitleCaseTranslation('core.domain.containersPerPallet'),
            },
            {
                key: 'includeTopCap',
                label: getTitleCaseTranslation('core.domain.includeTopCap'),
            },
            {
                key: 'topCapItemNumber',
                label: getTitleCaseTranslation('core.domain.topCapItemNumber'),
            },
            {
                key: 'effectiveDateFrom',
                label: getTitleCaseTranslation('core.domain.effectiveDateFrom'),
            },
            {
                key: 'effectiveDateTo',
                label: getTitleCaseTranslation('core.domain.effectiveDateTo'),
            },
            {
                key: 'enabled',
                label: getTitleCaseTranslation('core.domain.enabled'),
            },
        ];

        return {
            getTranslation,
            NotificationStoreEvents,
            productionPartNoticeService,
            tableColumns,
            updateDataRoute,
        };
    },
});
